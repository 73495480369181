<template>
  <div class="home">
    <div class="left">
      <h3>Hi, I'm Mark.</h3>
      <!-- <p>I have always been curious to understand the inner workings of different tools that we use everyday. This often leads me to dive into problems that I am passionate about. Whether I am restoring bikes or investigating new challenges, I feel fulfilled mastering new skills and using them to create unique and innovative solutions of my own. 

Working as a bicycle mechanic, I was able to capitalize on my passion for learning and problem-solving to help others understand the tools they use everyday.
Later I expanded my toolkit to effectively strategize with others and improve instruction on the fly in a middle-school classroom. 

I am excited to bring these hard-earned skills into my next role as a software developer in a company that will encourage my continued growth and support me in practicing creative and strategic problem-solving.
</p> -->
      <!-- <p>I am a software developer in Columbus, Ohio. I recently completed a full-stack software development certificate at Tech Elevator, a highly esteemed coding bootcamp. During this time I logged 700+ hours building and testing full-stack web applications using technologies such as Java, Javascript, PostgreSQL, Vue.js, and Git to name a few. Through this program I worked on agile teams, created efficient programs, and built websites from the ground up. After graduation, I have continued pursuing hands-on learning through integrating new front-end technologies into personal projects and freelance work with small businesses in my community. I have developed a passion for both front-end and back-end product design, and using my development expertise to create intuitive user-experiences that showcase the beauty and functionality of the product, using tools like Figma and Vuetify.
      </p> -->
      <p>I am a full-stack Java developer from Columbus, Ohio. I've gotten to wear many different hats throughout my career; always learning to create unique solutions to unique problems in whatever setting I found myself within. Whether in restaurants, bicycle shops, or middle-school classrooms, I have always sought to gather as much knowledge as I can to grow my skills and meet the needs of my team! I am an obsessive learner who loves to figure out how things work and how I can make them better, which ultimately led me to software development.</p>
      <p>Prior to moving into technology, I acquired a robust set of skills over my 10 years of professional experience. While working for small businesses and in education, I worked closely with clients and students to empower and build understanding and self-efficacy. In addition, my strategic problem solving skills, creativity, and collaboration will translate well into software development.
</p>
<p style="font-weight:bold">Thanks!</p>
    </div>
    <div class="right">
      <h3 style="font-style:oblique">Technical Skills</h3>
      <p>● Object-Oriented Programming: Java</p>
      <p>● Web Application Development: HTML, CSS, JavaScript, Spring Boot, Vue.js</p>
      <p>● Database Programming: JDBC, Table Design, SQL, PostgreSQL, E/R diagrams</p>
      <p>● Development Tools and Techniques: Agile, Unit Testing (JUnit), Integration Testing, Unix Command Line
navigation, Git, IntelliJ</p>
      <h4 style="font-style:oblique">Other Technologies</h4>
      <p>● UI Design and Wire-framing: Figma, Adobe Creative Suite</p>
      <p>● Web Hosting Tools: Amazon Web Services (AWS Amplify), Github pages</p>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from '../components/Landing.vue'

export default {
  name: 'HomeView',
  components: {
    Landing
  }
}
</script>

<style scoped>
.home {
  font-weight:bold;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 10%;
}

@media screen and (max-width: 628px) {
  .left {
    width: 90%;
  }
  .left p , .right p{
    margin: 0%;
  }
  .right {
    width: 90%;

  }
}

.left {
  width: 50%;
  min-width: 340px;
}
.left p , .right p {
  font-weight: lighter;
  margin: 6px;
}
.right {
  min-width: 340px;
  width: 45%;
  align-self: center;
  padding-bottom: 8%;
}
</style>
