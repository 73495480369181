<template>
  <div class="projects">
    <ul class="list">
      <li>
        <div class="project">
          <h3>Community Recruiters</h3>
          <h4>Full-stack Application</h4>
          <p>Full-stack web application that utilizes Java, Spring Boot, PostgreSQL, and Vue.js to provide a platform for external job-posting, recruiting, network organization, and candidate resume submittal.</p>
        </div>
      </li>
      <li>
        <div class="project">
          <h3>Double G Spray Booth</h3>
          <h4>Responsive Website</h4>
          <p>Responsive website using Vue.js and AWS Amplify to showcase product photos, provide contact information, and automatically deliver customer inquiry forms used for price quoting and contact information.</p>
        </div>
      </li>
      <li>
        <div class="project">
          <h3>VELOcity Cycling Tracker</h3>
          <h4>Full-stack GPS Routing Application</h4>
          <p>Worked with an Agile team of four developers to designed and
executed a full-stack bicycle routing application that consumes multiple Google Maps API’s and utilized
CSS, Javascript, Java, PostgreSQL, Spring, MVC, DAO, and Vue.js.</p>
        </div>
      </li>
      <li>
        <div class="project">
          <h3>TEnmo Money Transfer Application</h3>
          <h4>Complete API</h4>
          <p>Created an online payment application that utilizes Spring Boot, RESTful API constraints, PostgreSQL, and JUnit Testing using the MVC design pattern.</p>
        </div>
      </li>
      <li>
        <div class="project">
          <h3>Wheel Build Calculator</h3>
          <h4>Command Line Application</h4>
          <p>Java application that allows mechanics to input measurements for each component variable and calculates the necessary spoke length in order to build a bicycle wheel.</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
name: 'projects'
}
</script>

<style scoped>
.projects {
  display: flex;
  flex: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-self: flex-start;
}
.list {
  list-style:square;
}
p {
  margin-left: 30px;
}
h4 {
  font-style: oblique;
}
/* h3 {
  border-block: 2px solid #4d4c54;
  background-color: white;
  width: fit-content;
  font-weight: bold;
  color: black;
  padding: 5px;

} */

</style>